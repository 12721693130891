import React from 'react';
import '../css/Home.css';

function Load() {
  return (
    <div className="load-container">
      <span>N</span>
    </div>
  )
}

export default Load;